<template>
  <div>
    <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/banner.jpg" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="my-list">
      <van-grid :border="false" :column-num="3">
        <van-grid-item to="/pension/healthy">
          <img src="../../assets/jg.png" alt="" />
          <span>健康档案</span>
        </van-grid-item>
        <van-grid-item to="/pension/shequ">
          <img src="../../assets/bm.png" alt="" />
          <span>社区养老</span>
        </van-grid-item>
        <van-grid-item>
          <img src="../../assets/zs.png" alt="" />
          <span>养生知识</span>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
}
.my-swipe img {
  width: 100%;
}
.my-list {
  margin-top: 15px;
  font-size: 14px;
}
.my-list img {
  width: 50%;
  margin-bottom: 10px;
}
</style>